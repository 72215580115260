import * as React from 'react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import { useCookies } from 'react-cookie';
import { verifyPassJwt, accessCookie, removeCookie } from 'src/utils/auth';
import { useApp } from './AppContext';
import * as scss from './App.module.scss';

export const App: React.FC = ({ children }) => {
  const [cookies] = useCookies([accessCookie]);
  const [, dispatch] = useApp();

  React.useEffect(() => {
    async function setPartner() {
      if (!cookies[accessCookie]) return;

      try {
        const payload = await verifyPassJwt(cookies[accessCookie]);

        if (payload.utm_campaign !== 'none') {
          dispatch({
            type: 'SET_PARTNER',
            payload: {
              partnerLogo: payload.brand_logo,
              partnerName: payload.utm_campaign,
            },
          });
        }
      } catch (error) {
        if (error?.code === 'ERR_JWS_SIGNATURE_VERIFICATION_FAILED') {
          removeCookie(accessCookie);
        }
      }
    }

    setPartner();
  }, [cookies, dispatch]);

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }} />
      <div className={clsx(scss.wrapper)}>{children}</div>
    </>
  );
};
