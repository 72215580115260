module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Max Resources","short_name":"Max Resources","description":"Max: It’s all here. Iconic series, award-winning movies, fresh originals, and family favorites featuring the worlds of Harry Potter, the DC Universe, and HBO. Discover the best entertainment for every mood. Introducing Max – the one to watch.","start_url":"/","background_color":"#000066","theme_color":"#ffffff","display":"standalone","icon":"./static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b0790576f7027a4143cfb5f399f7872b"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
