import * as React from 'react';
import TagManager from 'react-gtm-module';
import 'focus-visible';
import './src/assets/scss/style.scss';
import 'swiper/swiper.min.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { AppRoot } from './src/components/App/AppRoot';
import { App } from './src/components/App/App';

const tagManagerArgs = {
  gtmId: 'GTM-5G76QJZ',
};

TagManager.initialize(tagManagerArgs);

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

/**
 * Create modal portal.
 * Portals provide a way to render children into a DOM node that exists outside the DOM hierarchy of the parent component.
 *
 * See: https://reactjs.org/docs/portals.html
 * See: https://spectrum.chat/gatsby-js/general/how-do-i-add-a-root-div-outside-the-app-root-in-gatsby-the-one-with-id-gatsby-so-i-can-mount-a-modal-using-the-react-portal-api~5a7c824f-fe6c-46d2-8242-dfdbc3f11129
 */
const modalRoot = document.createElement('div');
modalRoot.id = 'modal-root';
document.body.appendChild(modalRoot);

/**
 * Add font awesome icon packs.
 */
library.add(far, fas);

export const wrapRootElement = AppRoot;

export const wrapPageElement = ({ element, props }) => {
  return <App {...props}>{element}</App>;
};
