import * as React from 'react';

type Action =
  | { type: 'SET_BRAND_LOGO'; payload: string }
  | {
      type: 'SET_PARTNER';
      payload: { partnerLogo?: string; partnerName?: string };
    }
  | { type: undefined };

type Dispatch = (action: Action) => void;
type Context = { state: State; dispatch: Dispatch } | undefined;

type State = {
  partnerLogo?: string;
  partnerName?: string;
};

const AppContext = React.createContext<Context>(undefined);

const initialState: State = {};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_BRAND_LOGO': {
      return { ...state, partnerLogo: action.payload };
    }

    case 'SET_PARTNER': {
      const { partnerLogo, partnerName } = action.payload;
      return { ...state, partnerLogo, partnerName };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

type AppProviderProps = { children: React.ReactNode };

function AppProvider({ children }: AppProviderProps) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

type UseApp = [State, Dispatch] & { state: State; dispatch: Dispatch };

function useApp() {
  const context = React.useContext(AppContext);

  if (context === undefined) {
    throw new Error('useApp must be used within a AccessProvider');
  }

  const res = [context.state, context.dispatch] as UseApp;
  res.state = res[0];
  res.dispatch = res[1];

  return res;
}

export { AppProvider, useApp };
