import * as React from 'react';
import { CookiesProvider } from 'react-cookie';
import { AppProvider } from './AppContext';
import { WrapRootElementBrowserArgs, WrapRootElementNodeArgs } from 'gatsby';

type AppRootProps = WrapRootElementBrowserArgs & WrapRootElementNodeArgs;

export const AppRoot = ({ element }: AppRootProps) => {
  return (
    <CookiesProvider>
      <AppProvider>{element}</AppProvider>
    </CookiesProvider>
  );
};
