import Cookies, { CookieAttributes } from 'js-cookie';
import { canUseDOM } from 'exenv';
import { JWTPayload, jwtVerify, SignJWT } from 'jose';
import { TextEncoder } from 'web-encoding';
import * as types from 'src/types';

export const accessCookie = process.env.APP_COOKIE_NAME ?? 'hbomtk.access';
const JWT_ISSUER = process.env.APP_URL;
const COOKIE_CONFIG: CookieAttributes = {
  expires: 365, // 1 year
};
const encoder = new TextEncoder();
const secretKey = encoder.encode(process.env.JWT_SECRET);

export const isLoggedIn = () => {
  if (!canUseDOM) return false;

  return !!getCookie(accessCookie);
};

export const getCookie = (key: string) => {
  return Cookies.get(key);
};

export const setCookie = (
  key: string = accessCookie,
  value = '',
  options = COOKIE_CONFIG
) => {
  Cookies.set(key, value, options);
};

export const removeCookie = (
  key: string = accessCookie,
  options?: CookieAttributes
) => {
  Cookies.remove(key, options);
};

export const setPassJwt = async (
  value: Omit<types.PassphraseParams, 'code'>
) => {
  const jwt = await new SignJWT(value)
    .setProtectedHeader({ alg: 'HS256' })
    .setIssuedAt()
    .setIssuer(JWT_ISSUER)
    .setExpirationTime('30d')
    .sign(secretKey);

  setCookie(accessCookie, jwt);
};

export const verifyPassJwt = async (
  jwt: string
): Promise<JWTPayload & Partial<types.PassphraseParams>> => {
  const { payload } = await jwtVerify(jwt, secretKey);
  return payload;
};
